import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class FileRequestDetailsForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Engineer Drop Details',
      subHeading: '',
      path1: 'File Requests',
      path2: 'Details',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getFileRequest();
  }

  render() {
    return (
      <div>
        <div className='panel'>
          <img
            src='/assets/pcfsolutions/loading-gear.gif'
            style={this.state.fileRequest ? { display: 'none' } : {}}
          />
        </div>
        <section className='panel' style={this.state.fileRequest ? {} : { display: 'none' }}>
          <header className='panel-heading'>
            <div className='panel-actions'>
              <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
            </div>

            <h2 className='panel-title'>
              Information for File Request:{' '}
              {this.state.fileRequest ? this.state.fileRequest.id : ''}
            </h2>
            <p className='panel-subtitle'>
              For File {this.state.fileRequest ? this.state.fileRequest.File.barcode : ''}
            </p>
          </header>
          <div className='panel-body'>
            <form className='form-horizontal form-bordered'>
              <div className='form-group'>
                <label className='col-md-2 control-label'>File Request Number:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest ? this.state.fileRequest.id : ''}
                  </p>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label'>Urgent:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest ? (this.state.fileRequest.urgent ? 'YES' : 'NO') : ''}
                  </p>
                </div>
              </div>
              {this.state.fileRequest ? (
                this.state.fileRequest.reason ? (
                  <div className='form-group'>
                    <label className='col-md-2 control-label'>Reason For Urgency:</label>
                    <div className='col-md-9'>
                      <p className='form-control-static'>{this.state.fileRequest.reason}</p>
                    </div>
                  </div>
                ) : null
              ) : null}
              <div className='form-group'>
                <label className='col-md-2 control-label'>From Box:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest
                      ? this.state.fileRequest.Box
                        ? this.state.fileRequest.Box.barcode
                        : 'N/A'
                      : ''}
                  </p>
                </div>
              </div>
              <div className='form-group'>
                <label className='col-md-2 control-label'>From Location:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest
                      ? this.state.fileRequest.Location
                        ? this.state.fileRequest.Location.name
                        : 'N/A'
                      : ''}
                  </p>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label'>Current Status:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest ? this.state.fileRequest.status : ''}
                  </p>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label'>Date Created:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest
                      ? moment(this.state.fileRequest.createdAt).format('DD/MM/YYYY HH:mm')
                      : ''}
                  </p>
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label'>User Created:</label>
                <div className='col-md-9'>
                  <p className='form-control-static'>
                    {this.state.fileRequest ? this.state.fileRequest.UserCreated.username : ''}
                  </p>
                </div>
              </div>

              {this.state.fileRequest ? (
                this.state.fileRequest.dateDelivered ? (
                  <div>
                    <div className='form-group'>
                      <label className='col-md-2 control-label'>Date Delivered:</label>
                      <div className='col-md-9'>
                        <p className='form-control-static'>
                          {moment(this.state.fileRequest.dateDelivered).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='col-md-2 control-label'>User Delivered:</label>
                      <div className='col-md-9'>
                        <p className='form-control-static'>
                          {this.state.fileRequest.UserDelivered.username}
                        </p>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='col-md-2 control-label'>Signature:</label>
                      <div className='col-md-9'>
                        <img
                          style={{ height: '50%', width: '50%' }}
                          src={`data:image/png;base64,${
                            this.state.fileRequest ? this.state.fileRequest.signature : null
                          }`}
                          alt='N/A'
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              {this.state.fileRequest ? (
                this.state.fileRequest.dateCancelled ? (
                  <div>
                    <div className='form-group'>
                      <label className='col-md-2 control-label'>Date Cancelled:</label>
                      <div className='col-md-9'>
                        <p className='form-control-static'>
                          {moment(this.state.fileRequest.dateCancelled).format('DD/MM/YYYY HH:mm')}
                        </p>
                      </div>
                    </div>

                    <div className='form-group'>
                      <label className='col-md-2 control-label'>User Cancelled:</label>
                      <div className='col-md-9'>
                        <p className='form-control-static'>
                          {this.state.fileRequest.UserCancelled.username}
                        </p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )
              ) : (
                ''
              )}

              <div className='form-group'></div>
            </form>
          </div>
        </section>
      </div>
    );
  }

  getFileRequest() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/files/getFileRequestDetails/${self.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the Location. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }

          self.props.updatePanelState({ heading: `File Request ${data.data.id}` });
          self.setState({ fileRequest: data.data });
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}
export default class fileRequestDetailsPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileRequestDetailsForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
