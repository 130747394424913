import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';

export default class Menu extends React.Component {
  componentWillMount() {
    this.state = { user: this.props.user };
  }

  render() {
    return (
      <aside id='sidebar-left' className='sidebar-left'>
        <div className='sidebar-header'>
          <div className='sidebar-title'>Navigation</div>
          <div
            className='sidebar-toggle hidden-xs'
            data-toggle-class='sidebar-left-collapsed'
            data-target='html'
            data-fire-event='sidebar-left-toggle'
          >
            <i className='fa fa-bars' aria-label='Toggle sidebar'></i>
          </div>
        </div>

        <div className='nano'>
          <div className='nano-content'>
            <nav id='menu' className='nav-main' role='navigation'>
              <ul className='nav nav-main'>
                <li>
                  <Link to={'/'}>
                    <i className='fa fa-home' aria-hidden='true'></i>
                    <span>Dashboard</span>
                  </Link>
                </li>
                {/*	<li className="nav-parent nav-expanded nav-active" */}
                <li className='nav-parent'>
                  <a>
                    <i className='fa fa-columns' aria-hidden='true'></i>
                    <span>View</span>
                  </a>
                  <ul className='nav nav-children'>
                    <li>
                      <Link to={'/files/view'}>View Files</Link>
                    </li>

                    <li>
                      <Link to={'/locations/view'}>View Locations</Link>
                    </li>
                    <li>
                      <Link to={'/Boxes/view'}>View Boxes</Link>
                    </li>
                    <li>
                      <Link to={'/users/view'}>View Users</Link>
                    </li>

                    <li>
                      <Link to={'/fileRequests/view'}>View File Requests</Link>
                    </li>
                  </ul>
                </li>

                <li className='nav-parent'>
                  <a>
                    <i className='fa fa-tasks' aria-hidden='true'></i>
                    <span>Infrastructure</span>
                  </a>
                  <ul className='nav nav-children'>
                    <li
                      style={{
                        display: this.state ? (this.state.user.super ? null : 'none') : null,
                      }}
                    >
                      <Link to={'/users/create'}>Create User</Link>
                    </li>
                    <li
                      style={{
                        display: this.state ? (this.state.user.super ? null : 'none') : null,
                      }}
                    >
                      <Link to={'/locations/create'}>Create Location</Link>
                    </li>
                    <li>
                      <Link to={'/fileRequests/create'}>Create File Request</Link>
                    </li>
                    <li>
                      <Link to={'/files/create'}>Create File</Link>
                    </li>
                  </ul>
                </li>

                <li className='nav-parent'>
                  <a>
                    <i className='fa fa-line-chart' aria-hidden='true'></i>
                    <span>Procedures</span>
                  </a>
                  <ul className='nav nav-children'>
                    <li>
                      <Link to={'/reports/create'}>Generate Report</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>

            <hr className='separator' />
          </div>
        </div>
      </aside>
    );
  }
}
