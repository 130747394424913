import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class DashboardViewForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Dashboard',
      subHeading: '',
      path1: 'Dashboard',
      path2: 'View',
    });
    this.state = {};

    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    this.setState({ username });
    this.setState({ role });
  }

  render() {
    return <div>Welcome, Please access the menu on the Left.</div>;
  }
}
export default class DashboardViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <DashboardViewForm />
      </DisplayPanel>
    );
  }
}
