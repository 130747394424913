import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class BoxViewForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'View Boxes',
      subHeading: '',
      path1: 'Boxes',
      path2: 'View',
    });
    this.state = { user: this.props.user };
  }

  componentDidMount() {
    this.getBoxes();
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Boxes</h2>
        </header>
        <div className='panel-body'>
          <table className='table table-bordered table-striped mb-none' id='datatable-default'>
            <thead>
              <tr>
                <th>Barcode</th>
                <th>Location</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {this.state.boxes ? (
                this.state.boxes.map(box => (
                  <tr key={box.id}>
                    <td> {box.barcode} </td>
                    <td> {box.Location.name} </td>
                    <td>
                      <Link to={`/boxes/details/${box.id}`}>Details</Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  getBoxes() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch('/api/Boxes/getAll', { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting Boxes. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }
          self.setState({ boxes: data.data });

          $('#datatable-default').dataTable();
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}

export default class BoxViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <BoxViewForm user={this.props.user} />
      </DisplayPanel>
    );
  }
}
