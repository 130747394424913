import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import moment from 'moment';
import 'whatwg-fetch';

import DisplayPanel from '../reusableComponents/displayPanel';

class FileDetailsForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'File Details',
      subHeading: '',
      path1: 'Files',
      path2: 'Details',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getFile();
    this.getHistories();
  }

  render() {
    return (
      <div>
        <section className='panel'>
          <header className='panel-heading'>
            <div className='panel-actions'>
              <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
            </div>

            <h2 className='panel-title'>
              File Information for {this.state.file ? this.state.file.barcode : ''}
            </h2>
            <p className='panel-subtitle'>
              See below for information regarding file{' '}
              {this.state.file ? this.state.file.barcode : ''}
            </p>
          </header>
          <div className='panel-body'>
            <form className='form-horizontal form-bordered'>
              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputDefault'>
                  Barcode
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.barcode : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  File Description
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.description : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Client Name
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.clientName : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Department Code
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.departmentCode : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Department Name
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.departmentName : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Lawyer Fee Earner Code
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.lawyerFeeEarnerCode : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Lawyer Name
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.lawyerName : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Matter Number
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.caseNumber : ''}
                  />
                </div>
              </div>

              <div className='form-group'>
                <label className='col-md-2 control-label' htmlFor='inputReadOnly'>
                  Matter Description
                </label>
                <div className='col-md-9'>
                  <input
                    className='form-control'
                    disabled='disabled'
                    value={this.state.file ? this.state.file.caseDescription : ''}
                  />
                </div>
              </div>
            </form>
          </div>
        </section>

        {this.state.box ? (
          <section className='panel'>
            <header className='panel-heading'>
              <div className='panel-actions'>
                <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
              </div>

              <h2 className='panel-title'>Current Box</h2>
            </header>
            <div className='panel-body'>
              <form className='form-horizontal form-bordered'>
                <div className='form-group'>
                  <div>
                    <label className='col-md-2 control-label' htmlFor='inputDefault'>
                      Box Barcode
                    </label>
                    <div className='col-md-9'>
                      <input
                        className='form-control'
                        disabled='disabled'
                        value={this.state.box ? this.state.box.barcode : ''}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        ) : null}

        {this.state.histories ? (
          <section className='panel'>
            <header className='panel-heading'>
              <div className='panel-actions'>
                <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
              </div>

              <h2 className='panel-title'>File History</h2>
            </header>
            <div className='panel-body'>
              <table className='table table-bordered table-striped mb-none' id='datatable-default'>
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Procedure</th>
                    <th>Description</th>
                    <th>User</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.histories
                    ? this.state.histories.map(history => (
                        <tr key={this.state.histories.indexOf(history)}>
                          <td> {moment(history.date).format('DD/MM/YYYY HH:mm')}</td>
                          <td> {history.name}</td>
                          <td> {history.description}</td>
                          <td> {history.user} </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
          </section>
        ) : null}
      </div>
    );
  }

  getFile() {
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/Files/getOne/${this.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          this.props.updatePanelState({
            error: `There was an issue getting File. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // We got the data, so we need to turn it into something to display
          if (data.success === false) {
            this.props.updatePanelState({ error: data.message });
            return;
          }
          this.setState({ file: data.data, box: data.data.Box });
        });
      })
      .catch(err => {
        this.props.updatePanelState({ error: err.message });
        //
      });
  }

  getHistories() {
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/Files/getFileHistories/${this.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          this.props.updatePanelState({
            error: `There was an issue getting File Histories. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // We got the data, so we need to turn it into something to display

          if (data.success === false) {
            this.props.updatePanelState({ error: data.message });
            return;
          }
          this.setState({ histories: data.data });
        });
      })
      .catch(err => {
        this.props.updatePanelState({ error: err.message });
        //
      });
  }
}

export default class FileDetailsPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileDetailsForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
