import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import Header from './header';
import Menu from './menu';

export default class Layout extends React.Component {
  componentWillMount() {
    const token = localStorage.getItem('jwt');
    if (!token) {
      window.location = '../login';
    } else {
      this.setState({ token });
    }
  }

  render() {
    const username = localStorage.getItem('username');
    const role = localStorage.getItem('role');
    const user = {
      username,
      role,
      super: role === 'super',
    };
    return (
      <div>
        <Header />
        <div className='inner-wrapper'>
          <Menu user={user} />

          <section role='main' className='content-body'>
            {this.props.children}
          </section>
        </div>
      </div>
    );
  }
}
