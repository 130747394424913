import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class LocationDetailsForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Location ',
      subHeading: '',
      path1: 'Locations',
      path2: 'Details',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getLocation();
  }

  render() {
    return (
      <div>
        {this.state.location ? (
          this.state.location.Files ? (
            <section className='panel'>
              <header className='panel-heading'>
                <div className='panel-actions'>
                  <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
                </div>
                {this.state.location ? <h2 className='panel-title'>Files</h2> : null}
              </header>
              <div className='panel-body'>
                <table
                  className='table table-bordered table-striped mb-none'
                  id='datatable-default2'
                >
                  <thead>
                    <tr>
                      <th>Barcode</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.location ? (
                      this.state.location.Files ? (
                        this.state.location.Files.map(item =>
                          item.consumable ? null : (
                            <tr key={item.id}>
                              <td> {item.barcode}</td>
                              <td>{item.description}</td>
                            </tr>
                          ),
                        )
                      ) : null
                    ) : (
                      <tr>
                        <td>Loading..</td>
                        <td>Loading..</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </section>
          ) : null
        ) : null}

        {this.state.boxes ? (
          <section className='panel'>
            <header className='panel-heading'>
              <div className='panel-actions'>
                <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
              </div>
              {this.state.location ? (
                this.state.boxes ? (
                  <h2 className='panel-title'>Boxes</h2>
                ) : null
              ) : null}
            </header>
            <div className='panel-body'>
              {this.state.boxes ? (
                <table
                  className='table table-bordered table-striped mb-none'
                  id='datatable-default3'
                >
                  <thead>
                    <tr>
                      <th>Box Barcode</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.boxes ? (
                      this.state.boxes ? (
                        this.state.boxes.map(box => (
                          <tr key={box.id}>
                            <td>
                              {' '}
                              <Link to={`/Boxes/details/${box.id}`}> {box.barcode} </Link>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td>Loading..</td>
                        </tr>
                      )
                    ) : null}
                  </tbody>
                </table>
              ) : null}
            </div>
          </section>
        ) : null}
      </div>
    );
  }

  getLocation() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/locations/getOneWithFilesAndBoxes/${self.props.id}`, {
      method: 'GET',
      headers: head,
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the Location. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }

          self.props.updatePanelState({ heading: `Location ${data.data.name}` });
          self.setState({ location: data.data, boxes: data.data.Boxes });
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}
export default class LocationDetailsPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <LocationDetailsForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
