import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class RegisterForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Create User',
      path1: 'Users',
      path2: 'Create',
    });
    this.setState({});
  }

  componentDidMount() {
    this.setState({ submitDisabled: false });
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Create User</h2>
        </header>
        <div className='panel-body'>
          <form
            className='form-horizontal form-bordered'
            onSubmit={event => this.registerSubmit(event)}
          >
            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputDefault'>
                Username
              </label>
              <div className='col-md-6'>
                <input
                  type='text'
                  className='form-control'
                  id='inputDefault'
                  required
                  ref={username => {
                    this.username = username;
                  }}
                  minLength={3}
                  maxLength={50}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputDefault'>
                Password
              </label>
              <div className='col-md-6'>
                <input
                  type='password'
                  className='form-control'
                  id='inputDefault'
                  minLength={5}
                  maxLength={50}
                  required
                  ref={password => {
                    this.password = password;
                  }}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputDefault'>
                Confirm Password
              </label>
              <div className='col-md-6'>
                <input
                  type='password'
                  className='form-control'
                  id='inputDefault'
                  minLength={5}
                  maxLength={50}
                  required
                  ref={passwordConfirmation => {
                    this.passwordConfirmation = passwordConfirmation;
                  }}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputDefault'>
                Permissions
              </label>
              <div className='col-md-6'>
                <select
                  className='form-control'
                  ref={permissions => {
                    this.perms = permissions;
                  }}
                >
                  <option>Super</option>
                  <option>Standard</option>
                  <option>ReadOnly</option>
                </select>
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
              <div className='col-md-6'>
                <button
                  type='submit'
                  className='btn btn-lg btn-success btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Create</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }

  registerSubmit(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const username = this.username.value;
    const password = this.password.value;
    const permissions = this.perms.value;
    const passwordConfirmed = this.passwordConfirmation.value;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      username,
      passwordEntered: password,
      passwordConfirmed,
      permissions,
    };

    fetch('/api/users/create', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue trying to create the User. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }
}

export default class RegisterPage extends React.Component {
  render() {
    return (
      // Oh Em Gee!
      <DisplayPanel>
        <RegisterForm />
      </DisplayPanel>
    );
  }
}
