import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class FileCreateForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Create File',
      path1: 'Files',
      path2: 'Create',
    });
    this.setState({ barcode: '' });
  }

  componentDidMount() {
    this.setState({ submitDisabled: false });
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Create File</h2>
        </header>
        <div>
          {this.state.sosData ? (
            <div>
              <div className='panel-body'>
                <form
                  className='form-horizontal form-bordered'
                  onSubmit={event => this.submit(event)}
                >
                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      File Barcode
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        value={this.state.barcode}
                        onChange={event => this.onChange(event)}
                        ref={barcode => {
                          this.barcode = barcode;
                        }}
                        required
                        minLength={3}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Description
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={description => {
                          this.description = description;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Department Name
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={departmentName => {
                          this.departmentName = departmentName;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Department Code
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={departmentCode => {
                          this.departmentCode = departmentCode;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Lawyer Fee Earner Code
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={lawyerFeeEarnerCode => {
                          this.lawyerFeeEarnerCode = lawyerFeeEarnerCode;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Lawyer Name
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={lawyerName => {
                          this.lawyerName = lawyerName;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Client Name
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={clientName => {
                          this.clientName = clientName;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Matter Number
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        disabled={true}
                        defaultValue={this.state.sosData.caseNumber}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Matter Description
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        defaultValue=''
                        ref={matterDescription => {
                          this.matterDescription = matterDescription;
                        }}
                        minLength={3}
                        maxLength={50}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-success btn-block'
                        disabled={this.state.submitDisabled}
                      >
                        <span>Create</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            <div>
              <div className='panel-body'>
                <form
                  className='form-horizontal form-bordered'
                  onSubmit={event => this.getSOSData(event)}
                >
                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Matter Number
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='text'
                        className='form-control'
                        id='inputDefault'
                        required
                        ref={matter => {
                          this.matter = matter;
                        }}
                        minLength={1}
                        maxLength={50}
                      />
                    </div>
                  </div>
                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-success btn-block'
                        disabled={this.state.submitDisabled}
                      >
                        <span> Create for Matter</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </section>
    );
  }

  onChange(event) {
    event.preventDefault();
    if (event.target === this.barcode) {
      this.setState({ barcode: this.barcode.value });
    }
  }

  getSOSData(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const matter = self.matter.value;

    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      matterNumber: matter,
    };

    fetch('/api/files/getSosData', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue trying to get SOS Data. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.matter = null;
            self.setState({ submitDisabled: false, sosData: data.data });
          }
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }

  submit(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      barcode: this.barcode.value,
      description: this.description.value,
      clientName: this.clientName.value,
      departmentCode: this.departmentCode.value,
      departmentName: this.departmentName.value,
      lawyerFeeEarnerCode: this.lawyerFeeEarnerCode.value,
      lawyerName: this.lawyerName.value,
      caseNumber: this.state.sosData.caseNumber,
      caseDescription: this.matterDescription.value,
    };

    fetch('/api/files/create', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue trying to create the File. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }
}

export default class RegisterPage extends React.Component {
  render() {
    return (
      // Oh Em Gee!
      <DisplayPanel>
        <FileCreateForm />
      </DisplayPanel>
    );
  }
}
