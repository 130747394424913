import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class ReportGenerationForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Generate Report',
      path1: 'Reports',
      path2: 'create',
    });
    this.setState({});
  }

  componentDidMount() {
    this.allDepartments = this.allDepartments.bind(this);
    this.setState({ submitDisabled: false });
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Create Report</h2>
        </header>
        <div className='panel-body'>
          <form
            className='form-horizontal form-bordered'
            onSubmit={event => this.reportGenerationSubmit(event)}
          >
            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputDefault'>
                Department
              </label>
              <div className='col-md-6'>
                <input
                  type='text'
                  className='form-control'
                  id='inputDefault'
                  required
                  ref={department => {
                    this.department = department;
                  }}
                  minLength={3}
                  maxLength={50}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
              <div className='col-md-6'>
                <button
                  type='submit'
                  className='btn btn-lg btn-success btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Create Report</span>
                </button>
              </div>
            </div>
          </form>
          <br />
          <form className='form-horizontal form-bordered'>
            <div className='form-group'>
              <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
              <div className='col-md-6'>
                <button
                  onClick={this.allDepartments}
                  className='btn btn-lg btn-success btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Create Report For All Departments</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }

  allDepartments(event) {
    this.reportGenerationSubmit(event, 'All Departments');
  }

  reportGenerationSubmit(event, override) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const department = override || this.department.value;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      department,
    };

    fetch('/api/reports/generate', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue trying to generate a report. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }
}

export default class ReportGenerationPage extends React.Component {
  render() {
    return (
      // Oh Em Gee!
      <DisplayPanel>
        <ReportGenerationForm />
      </DisplayPanel>
    );
  }
}
