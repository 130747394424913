import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { browserHistory } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class FileArchiveForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Archive File',
      subHeading: '',
      path1: 'Files',
      path2: 'Archive',
    });
    this.state = {};
  }

  componentDidMount() {
    this.setState({ submitDisabled: false });
    this.getFile();
  }

  render() {
    return (
      <div>
        <div className='panel'>
          <img
            src='/assets/pcfsolutions/loading-gear.gif'
            style={this.state.file ? { display: 'none' } : {}}
          />
        </div>
        <section className='panel' style={this.state.file ? {} : { display: 'none' }}>
          <header className='panel-heading'>
            <div className='panel-actions'>
              <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
            </div>

            <h2 className='panel-title'>
              Archive File: {this.state.file ? this.state.file.barcode : ''}
            </h2>
          </header>
          {this.state.file ? (
            <div className='panel-body'>
              <div className='col-md-12'>
                <button
                  type='submit'
                  onClick={() => this.cancel(this.state.file.id)}
                  className='btn btn-lg btn-danger btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Archive </span>
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    );
  }

  getFile() {
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/Files/getOne/${this.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          this.props.updatePanelState({
            error: `There was an issue getting File. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // We got the data, so we need to turn it into something to display

          if (data.success === false) {
            this.props.updatePanelState({ error: data.message });
            return;
          }
          this.setState({ file: data.data });
        });
      })
      .catch(err => {
        this.props.updatePanelState({ error: err.message });
        //
      });
  }

  cancel() {
    const self = this;
    this.setState({ submitDisabled: true });
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);

    fetch(`/api/files/archive/${self.props.id}`, { method: 'POST', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `Looks like there was a problem. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            browserHistory.push('/files/view');
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
        //
      });
  }
}

export default class FileArchivePage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileArchiveForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
