import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class FileEditForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Create File',
      path1: 'Files',
      path2: 'Create',
    });
    this.setState({});
  }

  componentDidMount() {
    this.getFile();
    this.setState({ submitDisabled: false });
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Edit File</h2>
        </header>

        {this.state.file ? (
          <div>
            <div className='panel-body'>
              <form
                className='form-horizontal form-bordered'
                onSubmit={event => this.update(event)}
              >
                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    File Barcode
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      required
                      ref={barcode => {
                        this.barcode = barcode;
                      }}
                      defaultValue={this.state.file.barcode}
                      minLength={3}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Description
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      required
                      ref={description => {
                        this.description = description;
                      }}
                      defaultValue={this.state.file.description}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Department Name
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.departmentName}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Department Code
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.departmentCode}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Lawyer Fee Earner Code
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.lawyerFeeEarnerCode}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Lawyer Name
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.lawyerName}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Client Name
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.clientName}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Matter Number
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.caseNumber}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    Matter Description
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      disabled={true}
                      required
                      defaultValue={this.state.file.caseDescription}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                </div>

                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                  <div className='col-md-6'>
                    <button
                      type='submit'
                      className='btn btn-lg btn-success btn-block'
                      disabled={this.state.submitDisabled}
                    >
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        ) : null}
      </section>
    );
  }

  update(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });
    const barcode = this.barcode.value;
    const description = this.description.value;

    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      barcode,
      description,
    };

    fetch(`/api/files/update/${self.props.id}`, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue trying to update the File. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }

  getFile() {
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/Files/getOne/${this.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          this.props.updatePanelState({
            error: `There was an issue getting File. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // We got the data, so we need to turn it into something to display

          if (data.success === false) {
            this.props.updatePanelState({ error: data.message });
            return;
          }
          this.setState({ file: data.data });
        });
      })
      .catch(err => {
        this.props.updatePanelState({ error: err.message });
        //
      });
  }
}

export default class RegisterPage extends React.Component {
  render() {
    return (
      // Oh Em Gee!
      <DisplayPanel>
        <FileEditForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
