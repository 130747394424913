import React from 'react';
import ReactDOM from 'react-dom';
import { Router, Route, browserHistory } from 'react-router';

import Layout from './layout';

import Dashboard from './dashboard';
import Boxes from './boxes';
import BoxDetails from './boxes.details';
import Users from './users';
import Register from './user.create';
import UsersEdit from './user.edit';
import Locations from './locations';
import LocationsEdit from './locations.edit';
import LocationsCreate from './locations.create';
import LocationsDetails from './locations.view';
import Files from './files';
import FilesCreate from './files.create';
import FilesEdit from './files.edit';
import FilesDetails from './files.details';
import FilesArchive from './files.archive';
import FileRequests from './fileRequests';
import FileRequestsDetails from './fileRequest.details';
import FileRequestsCancel from './fileRequest.cancel';
import FileRequestsCreate from './fileRequest.create';
import ReportGeneration from './reportGeneration';

const NotFound = () => <h1>404.. Page not found!</h1>;

const Authorization = allowedRoles => WrappedComponent =>
  class WithAuthorization extends React.Component {
    constructor(props) {
      super(props);

      const username = localStorage.getItem('username');
      const role = localStorage.getItem('role');
      this.state = {
        user: {
          username,
          role,
          super: role === 'super',
        },
      };
    }

    render() {
      const { role } = this.state.user;
      if (allowedRoles.includes(role)) {
        return <WrappedComponent user={this.state.user} {...this.props} />;
      }
      return <h1> You do not have access to this page. </h1>;
    }
  };

const superUsers = ['super'];
// eslint-disable-next-line no-unused-vars
const standardUsers = ['standard'];
// eslint-disable-next-line no-unused-vars
const superAndStandards = ['super', 'standard'];
const everyone = ['super', 'standard', 'readOnly'];

const router = (
  <Router history={browserHistory}>
    <Route path='' component={Layout}>
      <Route path='/' component={Authorization(everyone)(Dashboard)} />
      <Route path='/users/view' component={Authorization(everyone)(Users)} />
      <Route path='/users/create' component={Authorization(superUsers)(Register)} />
      <Route path='/users/edit/:id' component={Authorization(superUsers)(UsersEdit)} />
      <Route path='/boxes/view' component={Authorization(everyone)(Boxes)} />
      <Route path='/boxes/details/:id' component={Authorization(everyone)(BoxDetails)} />

      <Route path='/locations/view' component={Authorization(everyone)(Locations)} />
      <Route path='/locations/edit/:id' component={Authorization(everyone)(LocationsEdit)} />
      <Route path='/locations/details/:id' component={Authorization(everyone)(LocationsDetails)} />
      <Route path='/locations/create' component={Authorization(everyone)(LocationsCreate)} />

      <Route path='/files/view' component={Authorization(everyone)(Files)} />
      <Route path='/files/details/:id' component={Authorization(everyone)(FilesDetails)} />
      <Route path='/files/create' component={Authorization(everyone)(FilesCreate)} />
      <Route path='/files/edit/:id' component={Authorization(everyone)(FilesEdit)} />
      <Route path='/files/archive/:id' component={Authorization(everyone)(FilesArchive)} />

      <Route path='/fileRequests/view' component={Authorization(everyone)(FileRequests)} />

      <Route
        path='/fileRequests/details/:id'
        component={Authorization(everyone)(FileRequestsDetails)}
      />
      <Route
        path='/fileRequests/cancel/:id'
        component={Authorization(everyone)(FileRequestsCancel)}
      />
      <Route path='/fileRequests/create' component={Authorization(everyone)(FileRequestsCreate)} />

      <Route path='/reports/create' component={Authorization(everyone)(ReportGeneration)} />

      <Route path='*' component={NotFound} />
    </Route>
  </Router>
);

ReactDOM.render(router, document.getElementById('hello'));
