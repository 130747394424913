import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import 'whatwg-fetch';
import moment from 'moment';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class FileRequestViewForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'View File Requests',
      subHeading: '',
      path1: 'File Requests',
      path2: 'View',
    });
    this.state = { user: this.props.user };
  }

  componentDidMount() {
    if (!this.state.fileRequests) {
      this.getFileRequests();
    }
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>File Requests</h2>
        </header>
        <div className='panel-body'>
          <table className='table table-bordered table-striped mb-none' id='datatable-default'>
            <thead>
              <tr>
                <th>File Request Number</th>
                <th>File Barcode</th>
                <th>Status</th>

                <th>Date Delivered</th>
                <th>Date Cancelled</th>
                <th>Urgent</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.fileRequests ? (
                this.state.fileRequests.map(fr => (
                  <tr key={fr.id}>
                    <td> {fr.id} </td>
                    <td> {fr.File.barcode} </td>
                    <td> {fr.status} </td>

                    <td>
                      {' '}
                      {fr.dateDelivered ? (
                        <span>{moment(fr.dateDelivered).format('DD/MM/YYYY HH:mm')}</span>
                      ) : (
                        <span>N/A</span>
                      )}{' '}
                    </td>
                    <td>
                      {' '}
                      {fr.dateCancelled ? (
                        <span>{moment(fr.dateCancelled).format('DD/MM/YYYY HH:mm')}</span>
                      ) : (
                        <span>N/A</span>
                      )}{' '}
                    </td>
                    <td> {fr.urgent ? <span>YES</span> : <span>NO</span>} </td>
                    <td>
                      {this.state.user.super ? (
                        <span>
                          <Link to={`/fileRequests/cancel/${fr.id}`}>Cancel</Link> |{' '}
                        </span>
                      ) : null}
                      <Link to={`/fileRequests/details/${fr.id}`}>Details</Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  getFileRequests() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);

    fetch('/api/files/getFileRequests', { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting File Requests. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          $('#datatable-default')
            .dataTable()
            .fnDestroy();

          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }
          // return the fileRequests
          self.setState({ fileRequests: data.data });

          $('#datatable-default').dataTable();
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}

export default class FileRequestViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileRequestViewForm user={this.props.user} />
      </DisplayPanel>
    );
  }
}
