import React from 'react';

export default class Form extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: '', index: 0, showSuggestions: false, suggestions: props.values || [] };
  }

  update(event) {
    this.setState({ value: event.target.value });
    this.props.onChange(event.target.value, this.setSuggestions.bind(this));
  }

  setSuggestions(values) {
    this.setState({ suggestions: values, index: 0, showSuggestions: values.length > 0 });
  }

  renderSuggestions() {
    return this.state.suggestions.map((item, index) => (
      <li
        data-index={index}
        onMouseDown={this.select.bind(this)}
        className={this.state.index === index ? 'active' : ''}
        key={index}
      >
        {item}
      </li>
    ));
  }

  select(event) {
    event.stopPropagation();
    const index = event.target.attributes['data-index'].value;
    this.setState({ value: this.state.suggestions[index] });
    this.input.blur();
    if (this.props.onSelect) {
      this.props.onSelect(this.state.suggestions[index]);
    }
  }

  keyPress(event) {
    switch (event.keyCode) {
      case 40:
        if (this.state.index < this.state.suggestions.length - 1) {
          this.setState({ index: this.state.index + 1 });
        }
        break;
      case 38:
        if (this.state.index > 0) {
          this.setState({ index: this.state.index - 1 });
        }
        break;
      // eslint-disable-next-line no-case-declarations
      case 13:
        const value = this.state.suggestions[this.state.index];
        this.setState({ showSuggestions: false, value });
        this.input.blur();
        if (this.props.onSelect) {
          this.props.onSelect(value);
        }
        break;
      default:
        break;
    }
  }

  renderChildren(props) {
    // eslint-disable-next-line consistent-return
    return React.Children.map(props.children, child => {
      if (child.type === 'input')
        return React.cloneElement(child, {
          onFocus: () => {
            this.setState({ showSuggestions: this.state.suggestions.length > 0 });
          },
          onBlur: () => {
            this.setState({ showSuggestions: false, index: 0 });
          },
          onChange: this.update.bind(this),
          onKeyDown: this.keyPress.bind(this),
          value: this.state.value,
          ref: input => {
            this.input = input;
          },
          autoComplete: 'off',
        });
    });
  }

  render() {
    return (
      <div>
        {this.renderChildren(this.props)}
        {this.state.showSuggestions ? (
          <div className='datalist'>
            <ul>{this.renderSuggestions()}</ul>
          </div>
        ) : null}
      </div>
    );
  }
}
