import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class LocationViewForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'View Locations',
      subHeading: '',
      path1: 'Locations',
      path2: 'View',
    });
    this.state = { user: this.props.user };
  }

  componentDidMount() {
    if (!this.state.locations) {
      this.getLocations();
    }
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Locations</h2>
        </header>
        <div className='panel-body'>
          <table className='table table-bordered table-striped mb-none' id='datatable-default'>
            <thead>
              <tr>
                <th>Barcode</th>
                <th>Name</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.locations ? (
                this.state.locations.map(store => (
                  <tr key={store.id}>
                    <td> {store.barcode} </td>
                    <td> {store.name} </td>
                    <td>
                      {this.state.user.super ? (
                        <span>
                          <Link to={`/locations/edit/${store.id}`}>Edit</Link> |{' '}
                        </span>
                      ) : null}
                      <Link to={`/locations/details/${store.id}`}>Details</Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  getLocations() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);

    fetch('/api/locations/getAll', { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting Locations. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          $('#datatable-default')
            .dataTable()
            .fnDestroy();

          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }
          // return the locations
          self.setState({ locations: data.data });

          $('#datatable-default').dataTable();
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}

export default class LocationViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <LocationViewForm user={this.props.user} />
      </DisplayPanel>
    );
  }
}
