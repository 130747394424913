import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class BoxDetailsForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Box Details',
      subHeading: '',
      path1: 'Box',
      path2: 'Details',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getBox();
  }

  render() {
    return (
      <div>
        <section className='panel'>
          <header className='panel-heading'>
            <div className='panel-actions'>
              <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
            </div>

            {this.state.box ? (
              <h2 className='panel-title'>Files in Box {this.state.box.barcode}</h2>
            ) : (
              <h2 className='panel-title'>Files in Box</h2>
            )}
          </header>
          <div className='panel-body'>
            <table className='table table-bordered table-striped mb-none' id='datatable-default'>
              <thead>
                <tr>
                  <th>Barcode</th>
                  <th>Description</th>
                  <th>Department</th>
                  <th>Case Number</th>
                </tr>
              </thead>
              <tbody>
                {this.state.files ? (
                  this.state.files ? (
                    this.state.files.map(file => (
                      <tr key={file.id}>
                        <td>
                          {' '}
                          <a href={`/files/details/${file.id}`}>{file.barcode}</a>{' '}
                        </td>
                        <td>{file.description}</td>
                        <td>{file.Department}</td>
                        <td>{file.caseNumber}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                    </tr>
                  )
                ) : null}
              </tbody>
            </table>
          </div>
        </section>
      </div>
    );
  }

  getBox() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/Boxes/getOneWithFiles/${self.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the Box. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
          } else {
            // return the fileTypes
            self.setState({ box: data.data, files: data.data.Files });
            $('#datatable-default').dataTable();
          }
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}
export default class BoxDetailsPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <BoxDetailsForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
