import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import DisplayPanel from '../reusableComponents/displayPanel';
import 'whatwg-fetch';

class LocationEditForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Edit Location',
      subHeading: 'Please enter the new Location Details below:',
      path1: 'Location',
      path2: 'Edit',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getLocation();
    this.setState({ submitDisabled: false });
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          {this.state.name ? (
            <h2 className='panel-title'>Edit Location {this.state.name}</h2>
          ) : (
            <h2 className='panel-title'>Edit Location</h2>
          )}
        </header>
        <div className='panel-body'>
          <form
            className='form-horizontal form-bordered'
            onSubmit={event => this.locationSubmit(event)}
          >
            <div className='form-group'>
              <label className='col-md-3 control-label'>Name</label>
              <div className='col-md-6'>
                <input
                  className='form-control'
                  placeholder='Location Name'
                  type='text'
                  required
                  minLength={3}
                  maxLength={50}
                  onChange={event => this.onChange(event)}
                  ref={name => {
                    this.name = name;
                  }}
                  value={this.state.name ? this.state.name : ''}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label'>Barcode</label>
              <div className='col-md-6'>
                <input
                  className='form-control'
                  placeholder='Location Barcode'
                  type='text'
                  required
                  minLength={3}
                  maxLength={50}
                  onChange={event => this.onChange(event)}
                  ref={barcode => {
                    this.barcode = barcode;
                  }}
                  value={this.state.barcode ? this.state.barcode : ''}
                />
              </div>
            </div>

            <div className='form-group'>
              <label className='col-md-3 control-label'></label>
              <div className='col-md-6'>
                <button
                  type='submit'
                  className='btn btn-lg btn-success btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Edit</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    );
  }

  onChange(event) {
    event.preventDefault();
    if (event.target === this.barcode) {
      this.setState({ barcode: this.barcode.value });
    }

    if (event.target === this.name) {
      this.setState({ name: this.name.value });
    }
  }

  locationSubmit(event) {
    const self = this;
    const name = self.name.value;
    const barcode = self.barcode.value;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = { barcode, name };
    fetch(`/api/locations/update/${self.props.id}`, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `Looks like there was a problem. Status Code: ${response.status}`,
            successMsg: '',
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
        //
      });
  }

  getLocation() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/locations/getOne/${self.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the Location. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          // We got the data, so we need to turn it into something to display
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
          } else {
            // set the info
            self.setState({ id: self.props.id, barcode: data.data.barcode, name: data.data.name });
          }
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
        //
      });
  }
}

export default class LocationEditPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <LocationEditForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
