import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class FileViewForm extends React.Component {
  componentWillMount() {
    this.handleCb = this.handleCb.bind(this);
    this.props.updatePanelState({
      error: '',
      heading: 'Create File Request',
      subHeading: '',
      path1: 'File Requests',
      path2: 'Create',
    });
    this.state = { user: this.props.user, checkBoxes: [], urgent: false };
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>
          <h2 className='panel-title'>Files In Storage</h2>
        </header>

        {this.state.Files ? (
          <div>
            <div className='panel-body'>
              <table className='table table-bordered table-striped mb-none' id='datatable-default'>
                <thead>
                  <tr>
                    <th>Barcode</th>
                    <th>Description</th>
                    <th>Client Name</th>
                    <th>Case Number</th>
                    <th>Box</th>
                    <th>Select</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.Files ? (
                    this.state.Files.map(file => (
                      <tr key={file.id}>
                        <td> {file.barcode} </td>
                        <td> {file.description} </td>
                        <td> {file.clientName} </td>
                        <td> {file.caseNumber} </td>
                        <td> {file.Box ? <div>{file.Box.barcode}</div> : ''} </td>
                        <td>
                          {' '}
                          <input type='checkbox' onChange={this.handleCb} id={file.id} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                      <td>Loading..</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className='panel-body'>
              <input
                type='checkbox'
                onChange={() => {
                  this.setState({ urgent: !this.state.urgent });
                }}
              />
              <label> Urgent?</label>
              {this.state.urgent ? (
                <div className='form-group'>
                  <label className='col-md-3 control-label' htmlFor='inputDefault'>
                    {' '}
                    Reason:
                  </label>
                  <div className='col-md-6'>
                    <input
                      type='text'
                      className='form-control'
                      id='inputDefault'
                      onChange={event => {
                        this.setState({ reason: event.target.value });
                      }}
                      minLength={3}
                      maxLength={50}
                    />
                  </div>
                  <br />
                </div>
              ) : null}

              <button
                type='submit'
                onClick={() => this.create()}
                className='btn btn-lg btn-success btn-block'
                disabled={this.state.submitDisabled}
              >
                <span> Create </span>
              </button>
            </div>
          </div>
        ) : (
          <div className='panel-body'>
            <form
              className='form-horizontal form-bordered'
              onSubmit={event => this.getFilesUsingMatter(event)}
            >
              <div className='form-group'>
                <label className='col-md-3 control-label' htmlFor='inputDefault'>
                  Enter Matter Number:{' '}
                </label>
                <div className='col-md-6'>
                  <input
                    type='text'
                    className='form-control'
                    id='inputDefault'
                    required
                    ref={matter => {
                      this.matter = matter;
                    }}
                    minLength={1}
                    maxLength={50}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                <div className='col-md-6'>
                  <button
                    type='submit'
                    className='btn btn-lg btn-success btn-block'
                    disabled={this.state.submitDisabled}
                  >
                    <span> Show Files</span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        )}
      </section>
    );
  }

  handleCb(event) {
    const { target } = event;
    const value = target.checked;
    const list = [...this.state.checkBoxes];
    const id = parseInt(target.id, 10);
    if (value) {
      list.push(id);
    } else {
      const index = list.indexOf(id);
      list.splice(index, 1);
    }
    this.setState({ checkBoxes: list });
  }

  create() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      fileIds: this.state.checkBoxes,
      urgent: this.state.urgent,
      reason: this.state.reason,
    };
    fetch('/api/files/fileRequest', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was a problem. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }

  getFilesUsingMatter(event) {
    event.preventDefault();
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    const matter = this.matter.value;
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = { matterCode: matter };
    fetch('/api/files/getFilesInStorageUsingMatter', {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting Files. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }
          self.setState({ Files: data.data });
          $('#datatable-default').dataTable();
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}

export default class FileViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileViewForm user={this.props.user} />
      </DisplayPanel>
    );
  }
}
