import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class UserEditForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Edit User',
      path1: 'Users',
      path2: 'Edit',
    });
    this.state = {};
  }

  componentDidMount() {
    this.getUser();

    this.setState({ submitDisabled: false });
  }

  render() {
    const self = this;
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='tabs'>
            <ul className='nav nav-tabs'>
              <li className='active'>
                <a href='#userDetails' data-toggle='tab'>
                  <i className='fa fa-star'></i>User Details
                </a>
              </li>
              <li>
                <a href='#completedAsns' data-toggle='tab'>
                  Update Password
                </a>
              </li>
            </ul>

            <div className='tab-content'>
              <div id='userDetails' className='tab-pane active'>
                <form
                  className='form-horizontal form-bordered'
                  onSubmit={event => this.editSubmit(event)}
                >
                  <p>
                    {this.state.warning
                      ? 'You will be logged out if you edit your own user.'
                      : null}
                  </p>
                  {this.state.warning ? <br /> : null}
                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Username
                    </label>
                    <div className='col-md-6'>
                      <input
                        className='form-control'
                        placeholder='Username'
                        required
                        ref={username => {
                          this.username = username;
                        }}
                        minLength={3}
                        maxLength={50}
                        value={self.state.username ? self.state.username : ''}
                        onChange={event => this.onChange(event)}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Permissions
                    </label>
                    <div className='col-md-6'>
                      <select
                        className='form-control'
                        ref={permissions => {
                          this.perms = permissions;
                        }}
                        value={self.state.permissions ? self.state.permissions : ''}
                        onChange={event => this.onChange(event)}
                      >
                        <option>Super</option>
                        <option>Standard</option>
                        <option>ReadOnly</option>
                      </select>
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-success btn-block'
                        disabled={this.state.submitDisabled}
                      >
                        <span> Edit</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>

              <div id='completedAsns' className='tab-pane'>
                <form
                  className='form-horizontal form-bordered'
                  onSubmit={event => this.updatePassword(event)}
                >
                  <p>
                    {this.state.warning
                      ? 'You will be logged out if you edit your own user.'
                      : null}
                  </p>
                  {this.state.warning ? <br /> : null}
                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      New Password
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='password'
                        className='form-control'
                        id='inputDefault'
                        minLength={5}
                        maxLength={50}
                        required
                        ref={password => {
                          this.password = password;
                        }}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputDefault'>
                      Confirm New Password
                    </label>
                    <div className='col-md-6'>
                      <input
                        type='password'
                        className='form-control'
                        id='inputDefault'
                        minLength={5}
                        maxLength={50}
                        required
                        ref={passwordConfirmation => {
                          this.passwordConfirmation = passwordConfirmation;
                        }}
                      />
                    </div>
                  </div>

                  <div className='form-group'>
                    <label className='col-md-3 control-label' htmlFor='inputSuccess'></label>
                    <div className='col-md-6'>
                      <button
                        type='submit'
                        className='btn btn-lg btn-success btn-block'
                        disabled={this.state.submitDisabled}
                      >
                        <span> Update Password</span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onChange(e) {
    if (e.target === this.username) {
      this.setState({ username: e.target.value });
    }
    if (e.target === this.perms) {
      this.setState({ permissions: e.target.value });
    }
  }

  getUser() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = { where: { id: self.props.id } };
    fetch(`/api/Users/getOne/${self.props.id}`, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the User information. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
          } else {
            // return the itemTypes
            const username = localStorage.getItem('username');
            let warning = false;
            if (username === data.user.username) {
              warning = true;
            }
            const permissions =
              data.user.permissions === 'super'
                ? 'Super'
                : data.user.permissions === 'standard'
                ? 'Standard'
                : data.user.permissions === 'readOnly'
                ? 'ReadOnly'
                : null;
            self.setState({
              user: data.user,
              username: data.user.username,
              permissions,
              warning,
            });
          }
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }

  updatePassword(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const password = this.password.value;
    const passwordConfirm = this.passwordConfirmation.value;
    if (!password || !passwordConfirm) {
      self.props.updatePanelState({ error: 'Please enter new password details', successMsg: '' });
      return;
    }
    if (password !== passwordConfirm) {
      self.props.updatePanelState({
        error: 'The Password and the confirmation do not match.',
        successMsg: '',
      });
      return;
    }
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = { passwordEntered: password, passwordConfirmed: passwordConfirm };
    fetch(`/api/users/updatePassword/${self.props.id}`, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `Looks like there was a problem. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: "User's Password Updated.", error: '' });
            if (self.state.warning) {
              // Log out!
              localStorage.removeItem('jwt');
              window.location = '/login';
            }
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }

  editSubmit(event) {
    const self = this;
    event.preventDefault();
    this.setState({ submitDisabled: true });

    const username = this.username.value;
    const permissions = this.perms.value;

    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = {
      username,
      permissions,
    };

    fetch(`/api/users/edit/${self.props.id}`, {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `Looks like there was a problem. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            self.props.updatePanelState({ successMsg: data.message, error: '' });
            if (self.state.warning) {
              if (self.state.warning) {
                // Log out!
                localStorage.removeItem('jwt');
                window.location = '/login';
              }
            }
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
      });
  }
}

export default class UserEditPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <UserEditForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
