import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import moment from 'moment';
import { browserHistory } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

class FileRequestDeleteForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'Cancel File Request',
      subHeading: '',
      path1: 'File Requests',
      path2: 'Cancel',
    });
    this.state = {};
  }

  componentDidMount() {
    this.setState({ submitDisabled: false });
    this.getFileRequest();
  }

  render() {
    return (
      <div>
        <div className='panel'>
          <img
            src='/assets/pcfsolutions/loading-gear.gif'
            style={this.state.fileRequest ? { display: 'none' } : {}}
          />
        </div>
        <section className='panel' style={this.state.fileRequest ? {} : { display: 'none' }}>
          <header className='panel-heading'>
            <div className='panel-actions'>
              <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
            </div>

            <h2 className='panel-title'>
              Cancel File Request: {this.state.fileRequest ? this.state.fileRequest.id : ''}
            </h2>
            <p className='panel-subtitle'>
              For File {this.state.fileRequest ? this.state.fileRequest.File.barcode : ''}
            </p>
            <p className='panel-subtitle'>
              Date Created{' '}
              {this.state.fileRequest
                ? moment(this.state.fileRequest.dateCreated).format('DD/MM/YYYY HH:mm')
                : ''}
            </p>
          </header>
          {this.state.fileRequest ? (
            <div className='panel-body'>
              <div className='col-md-12'>
                <button
                  type='submit'
                  onClick={() => this.cancel(this.state.fileRequest.id)}
                  className='btn btn-lg btn-danger btn-block'
                  disabled={this.state.submitDisabled}
                >
                  <span> Cancel </span>
                </button>
              </div>
            </div>
          ) : (
            ''
          )}
        </section>
      </div>
    );
  }

  getFileRequest() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    fetch(`/api/files/getFileRequestDetails/${self.props.id}`, { method: 'GET', headers: head })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting the Location. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }

          self.props.updatePanelState({ heading: `File Request ${data.data.id}` });
          self.setState({ fileRequest: data.data });
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }

  cancel(id) {
    const self = this;
    this.setState({ submitDisabled: true });
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);

    const json = { fileRequestId: id };
    fetch('/api/files/cancelFileRequest', {
      method: 'POST',
      headers: head,
      body: JSON.stringify(json),
    })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `Looks like there was a problem. Status Code: ${response.status}`,
          });
          self.setState({ submitDisabled: false });
          return;
        }
        response.json().then(data => {
          if (!data.success) {
            self.props.updatePanelState({ error: data.message, successMsg: '' });
          } else {
            browserHistory.push('/fileRequests/view');
            // window.location = '/fileRequests/view';
          }
        });
        self.setState({ submitDisabled: false });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message, successMsg: '' });
        self.setState({ submitDisabled: false });
        //
      });
  }
}

export default class FileRequestDeletePage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <FileRequestDeleteForm id={this.props.params.id} />
      </DisplayPanel>
    );
  }
}
