import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';

export default class DisplayPanel extends React.Component {
  componentWillMount() {
    this.setState({ error: '', successMsg: '', heading: '', subHeading: '' });
  }

  render() {
    return (
      <div>
        <header className='page-header'>
          <h2>{this.state.heading}</h2>
          <div className='right-wrapper pull-right'>
            <ol className='breadcrumbs'>
              <li>
                <Link to={'/'}>
                  <i className='fa fa-home'></i>
                </Link>
              </li>
              {/* <li><span><Link to={`/${this.state.path1}/view`}>{this.state.path1}</Link></span></li> */}
              <li>
                <span>{this.state.path1}</span>
              </li>
              <li>
                <span>{this.state.path2}</span>
              </li>
            </ol>
            <div className='sidebar-right-toggle'></div>
          </div>
        </header>

        <div>
          {/* <label>{this.state.subHeading}</label> */}

          <div>
            <div
              className='alert alert-danger'
              style={{ display: this.state.error ? 'block' : 'none' }}
            >
              {this.state.error}
            </div>
            <div
              className='alert alert-success'
              style={{ display: this.state.successMsg ? 'block' : 'none' }}
            >
              {this.state.successMsg}
            </div>
            {React.cloneElement(this.props.children, {
              updatePanelState: state => this.updatePanelState(state),
            })}
          </div>
        </div>
      </div>
    );
  }

  updatePanelState(state) {
    if (state.error) {
      if (state.error.includes('Validation error')) {
        // eslint-disable-next-line no-param-reassign
        state.error = 'You have entered invalid information. Please amend and try again.';
      } else if (state.error.includes('Failed to fetch')) {
        // eslint-disable-next-line no-param-reassign
        state.error =
          'There was an issue connecting to the server. Please check your network connection and try again.';
      } else if (state.error.includes('{"code":"EREQUEST')) {
        // eslint-disable-next-line no-param-reassign
        state.error =
          'There was an issue generating your report. Please check that your dates are formatted correctly before trying again.';
      }
    }
    this.setState(state);
  }
}
