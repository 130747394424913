import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import 'whatwg-fetch';

export default class HeaderUser extends React.Component {
  render() {
    return (
      <div id='userbox' className='userbox'>
        <a href='#' data-toggle='dropdown'>
          <figure className='profile-picture'>
            <img
              src='/assets/pcfsolutions/user.png'
              alt={this.props.loggedInUser}
              className='img-circle'
              data-lock-picture='/assets/pcfsolutions/user.png'
            />
          </figure>
          <div className='profile-info' data-lock-name={this.props.loggedInUser}>
            <span className='name'> {this.props.loggedInUser} </span>
            <span className='role'>{this.props.role}</span>
          </div>

          <i className='fa custom-caret'></i>
        </a>

        <div className='dropdown-menu'>
          <ul className='list-unstyled'>
            <li className='divider'></li>
            {/* <li>
              <a role="menuitem" tabIndex="-1" href="pages-user-profile.html"><i className="fa fa-user"></i> My Profile</a>
            </li> */}
            <li>
              <a role='menuitem' onClick={() => this.logOut()} tabIndex='-1' href='#'>
                <i className='fa fa-power-off'></i> Logout
              </a>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  logOut() {
    // event.preventDefault();
    this.setState({ token: '' });
    localStorage.removeItem('jwt');
    window.location = '/login';
  }
}
