import React from 'react';
// eslint-disable-next-line no-unused-vars
import ReactDOM from 'react-dom';
import { Link } from 'react-router';
import 'whatwg-fetch';
import DisplayPanel from '../reusableComponents/displayPanel';

const { $ } = window;

class UserViewForm extends React.Component {
  componentWillMount() {
    this.props.updatePanelState({
      error: '',
      heading: 'View Users',
      subHeading: '',
      path1: 'Users',
      path2: 'View',
    });
    this.state = { user: this.props.user };
  }

  componentDidMount() {
    this.getUsers();
  }

  render() {
    return (
      <section className='panel'>
        <header className='panel-heading'>
          <div className='panel-actions'>
            <a href='#' className='panel-action panel-action-toggle' data-panel-toggle></a>
          </div>

          <h2 className='panel-title'>Users</h2>
        </header>
        <div className='panel-body'>
          <table className='table table-bordered table-striped mb-none' id='datatable-default'>
            <thead>
              <tr>
                <th>Username</th>
                <th>Permissions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {this.state.users ? (
                this.state.users.map(user => (
                  <tr key={user.id}>
                    <td> {user.username} </td>
                    <td> {user.permissions} </td>
                    <td>
                      <div
                        style={{
                          display: this.state ? (this.state.user.super ? null : 'none') : null,
                        }}
                      >
                        {' '}
                        <Link to={`/users/edit/${user.id}`}>Edit</Link>
                      </div>{' '}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>Loading..</td>
                  <td>Loading..</td>
                  <td>Loading..</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </section>
    );
  }

  getUsers() {
    const self = this;
    const token = localStorage.getItem('jwt');
    const head = new Headers();
    head.append('Content-Type', 'application/json');
    head.append('Authorization', token);
    const json = { where: null, lastId: null };
    fetch('/api/Users/getAll', { method: 'POST', headers: head, body: JSON.stringify(json) })
      .then(response => {
        if (response.status === 401) {
          this.setState({ token: '' });
          localStorage.removeItem('jwt');
          window.location = '/login';
        }
        if (response.status !== 200) {
          self.props.updatePanelState({
            error: `There was an issue getting Users. Status Code: ${response.status}`,
          });
          return;
        }
        response.json().then(data => {
          if (data.success === false) {
            self.props.updatePanelState({ error: data.message });
            return;
          }
          self.setState({ users: data.users });

          $('#datatable-default').dataTable();
        });
      })
      .catch(err => {
        self.props.updatePanelState({ error: err.message });
      });
  }
}

export default class UserViewPage extends React.Component {
  render() {
    return (
      <DisplayPanel>
        <UserViewForm user={this.props.user} />
      </DisplayPanel>
    );
  }
}
